import React, { useState } from 'react'
import Headertwo from './Headertwo'
import Footertwo from './Footertwo'
import { Col,  Container,  Row } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel';


export const Works = () => {
  const [index, setIndex] = useState(0);
  const [index1, setIndex1] = useState(0);
  const [index2, setIndex2] = useState(0);
  const [index3, setIndex3] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };


  const handleSelect1 = (selectedIndex1) => {
    setIndex1(selectedIndex1);
  };
  const handleSelect2 = (selectedIndex2) => {
    setIndex2(selectedIndex2);
  };
  const handleSelect3 =(selectedIndex3)=>{
    setIndex3(selectedIndex3);
  }
  return (
    <>
      <div className='group-section'>
        <Headertwo />
        <div className='banner-item-how-it-work'>
         
        </div>
      </div>
      <Container>
     <Row>
      <Col md ="12">
      <h2 className='mb-4 mt-4 text-center  heading-how-itswork'>
      How it works for Manager
     </h2>
      
      </Col>
      <Col md="12">
      <video width="100%" controls autoPlay muted>
        <source src="../image/manager.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
      </Col>
      <Col md ="12">
      <h2 className='mb-4 mt-4 text-center  heading-how-itswork'>
      How it works for Player
     </h2>
      
      </Col>
      <Col md="12">

      <video width="100%" controls autoPlay muted>
        <source src="../image/players.mp4" type="video/mp4" />
        Your browser does not support the video tag.
    </video>
  
      </Col>
     </Row>
     </Container>
    

      <section className='p-5'>
        <h4 className='mb-4 text-center  heading-how-itswork'>If you are manager then follow these steps to create an auction.</h4>
        <Row>
          <Col  md={8} className="mx-auto">
          <Carousel activeIndex={index} onSelect={handleSelect} fade interval={3000}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="../image/step/Step-1.jpg"
          alt="First slide"
        />
       
        <Carousel.Caption>
  
      
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-2.jpg"
          alt="First slide"
        />
        <Carousel.Caption>

         
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-3.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-4.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-5.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-6.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-7.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-8.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-9.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-10.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-11.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/Step-12.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
      
       
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
          </Col>
        </Row>
  

        <Row>
          <Col md={8} className='mx-auto'>
          <h4 className='text-center mt-4 mb-4  heading-how-itswork'>If you are player then follow these steps to place a bid.</h4>
          <Carousel activeIndex={index1} onSelect={handleSelect1} fade interval={3000}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="../image/player-step/Step-1.jpg"
          alt="First slide"
        />
       
        <Carousel.Caption>
  
      
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/player-step/Step-2.jpg"
          alt="First slide"
        />
        <Carousel.Caption>

         
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/player-step/Step-3.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      

      
    </Carousel>
          </Col>
        </Row>






        <Row>
          <Col md={8} className='mx-auto'>
          <h4 className='text-center mt-4 mb-4 heading-how-itswork'>If you are manager then follow these steps to setup bracket</h4>
          <Carousel activeIndex={index2} onSelect={handleSelect2} fade interval={3000}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="../image/step/bracket/Step-1.jpg"
          alt="First slide"
        />
       
        <Carousel.Caption>
  
      
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
         src="../image/step/bracket/Step-2.jpg"
          alt="First slide"
        />
        <Carousel.Caption>

         
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-3.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-4.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-5.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-6.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-7.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>


      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-8.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-9.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-10.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-11.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/step/bracket/Step-12.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
          </Col>
        </Row>

        <Row>
          <Col md={8} className='mx-auto'>
          <h4 className='text-center mt-4 mb-4 heading-how-itswork'>If you are player then follow these steps in secondary market to sell the bid and place the bid.</h4>
          <Carousel activeIndex={index3} onSelect={handleSelect3} fade interval={3000}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="../image/secondary-market-step/Step-1.jpg"
          alt="First slide"
        />
       
        <Carousel.Caption>
  
      
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
         src="../image/secondary-market-step/Step-2.jpg"
          alt="First slide"
        />
        <Carousel.Caption>

         
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/secondary-market-step/Step-3.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/secondary-market-step/Step-4.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/secondary-market-step/Step-5.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/secondary-market-step/Step-6.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src="../image/secondary-market-step/Step-7.jpg"
          alt="First slide"
        />
        <Carousel.Caption>
         
        
        </Carousel.Caption>
      </Carousel.Item>


     
    
    
    </Carousel>
          </Col>
        </Row>





   
      </section>







      <Footertwo />
    </>
  )
}
