import React, { useEffect, useState } from 'react'
import TopNavbar from '../topNavbar/TopNavbar';
import Sidebar from '../sidebar/Sidebar';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import '../../assets/css/scss/main.css';
import * as icons from 'react-bootstrap-icons';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import { ChangePasswordApi, UserGetId } from '../../redux/auth-reducer/auth-service';
import { GetToken, GetUser } from '../../utils/helper/helper';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { Footer } from '../../Footer';

export const ChangePassword = () => {
  const [validated, setValidated] = useState(false);
  const [err, setErr] = useState("");
  const user = JSON.parse(GetUser());
  let [visible, setVisible] = useState(false);
  let [visibleIconPass, setVisibleIconPass] = useState(false);
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(true);
  const [addPassword, setAddPassword] = useState({
    CurrrentPassword: "",
    newpassword: "",
    confirmPassword: "",
  });
  const handelform = (e) => {
    setAddPassword({
      ...addPassword,
      [e.target.name]: e.target.value
    })

  }




  const handelformPass = (e) => {
    setAddPassword({
      ...addPassword,
      newpassword: e.target.value
    })
    setIsValid(isPasswordValid(e.target.value));

  }
  const isPasswordValid = (newpassword) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/;
    return passwordRegex.test(newpassword);
  };


  const token = GetToken()

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    const value = {
      "emailId": userData?.emailId,
      "currentPassword": addPassword?.CurrrentPassword,
      "newPassword": addPassword?.newpassword,
      "confirmPassword": addPassword?.confirmPassword

    }
    await ChangePasswordApi(value, token).then((res) => {
      if (res) {
        toast.success("Password changed successfully");
          navigate(-1);
      } else {
        setAddPassword({
          CurrrentPassword: "",
          newpassword: "",
          confirmPassword: "",
        })
        toast.error("Current Password is wrong");
      }

    });


  };


  useEffect(() => {
    async function UserGet() {
      await UserGetId().then((res) => {
        setUserData(res);

      })
    }
    UserGet();
  }, []);

  const [data, setData] = useState(false);
  function sidebarfun() {
    setData(!data)
  }
  const show = (event) => {
    setVisible(!visible)
  }

  const showIconPass = () => {
    setVisibleIconPass(!visibleIconPass)
  }

  useEffect(() => {
    if (addPassword?.newpassword !== addPassword?.confirmPassword && addPassword?.newpassword?.length > 0 && addPassword?.confirmPassword?.length > 0) {
      setErr("Password and confirmPassword does not match")
    } else {
      setErr("")
    }
  }, [addPassword?.newpassword, addPassword?.confirmPassword])
  return (
    <>
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group" >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <h1 className="heading-dash">Change Password</h1>
          <div className='ChangePass'>
            <Form noValidate validated={validated} className='Login-form' onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col className='col-sm-6 offset-md-0'>
                  <Form.Group as={Col} md="12" controlId="validationCustomFristname">
                    <Form.Label>Current password</Form.Label>
                    <InputGroup hasValidation>


                      <Form.Control
                        type={visible ? "text" : "password"}
                        value={addPassword?.CurrrentPassword}
                        onChange={handelform}
                       className='password-input-Change'
                        name="CurrrentPassword"
                        placeholder="Enter current Password"
                        aria-describedby="inputGroupPrepend"
                        required
                      />

                      <div className='eye-icon' onClick={show}>
                        {
                          visible ? <icons.Eye /> : <icons.EyeSlash />
                        }

                      </div>

                      <Form.Control.Feedback type="invalid">
                        Please Enter a Current Password.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>




                  <Form.Group as={Col} md="12" >
                    <Form.Label>Password</Form.Label>
                    <InputGroup hasValidation>


                      <Form.Control
                        type={visible ? "text" : "password"}
                        onChange={handelformPass}
                        value={addPassword?.newpassword}
                          className='password-input-Change'
                        name="newpassword"
                        placeholder="Enter password"
                        aria-describedby="inputGroupPrepend"
                        isInvalid={!isValid}
                        required
                      />
                      <div className='eye-icon' onClick={show}>
                        {
                          visible ? <icons.Eye /> : <icons.EyeSlash />
                        }

                      </div>

                      <Form.Control.Feedback type="invalid">
                        Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>


                  <Form.Group as={Col} md="12" className="text-left" >
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup hasValidation>




                      <Form.Control
                        type={visibleIconPass ? "text" : "password"}
                        name='confirmPassword'
                        className='password-input-Change'
                        autocomplete="on"
                        onChange={handelform}
                        value={addPassword?.confirmPassword}

                        placeholder="Enter confirm password"
                        required
                      />
                      <div className='eye-icon' onClick={showIconPass}>
                        {
                          visibleIconPass ? <icons.Eye /> : <icons.EyeSlash />
                        }

                      </div>
                      {
                        err ? <p className='err-message'>Password and confirmPassword does not match.</p> : <Form.Control.Feedback type="invalid">
                          Please Enter a Confirm Password.
                        </Form.Control.Feedback>
                      }

                    </InputGroup>
                  </Form.Group>

                  <Col md="12 ">
                    <Button type="submit" className='submit'>submit</Button>
                  </Col>

                </Col>

              </Row>
            </Form>
          </div>
          <Footer />
        </div>
      </div>
    </>
  )
}
export default ChangePassword;