import React, { useEffect, useState } from 'react'

import TopNavbar from '../topNavbar/TopNavbar'
import Sidebar from '../sidebar/Sidebar';
import { Card, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import * as icons from 'react-bootstrap-icons';
import { Footer } from '../../Footer';
import { PlayerGetById, OragnizationCity, OragnizationCounrty, OragnizationStates, UploadImage, WebAdminProfile, ManagerGetById } from '../../redux/auth-reducer/auth-service';
import { ImageUrl } from '../../utils/config/config';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import { DatePicker } from 'rsuite';
import flags from 'react-phone-number-input/flags'
import dayjs from 'dayjs'
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useSelector } from 'react-redux';

export const Profile = () => {
  const [data, setData] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [dataImage, setDataImage] = useState();
  const [showImage, setShowImage] = useState(false);
  const [countryData, setCountryData] = useState();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [states, setStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  let [city, setCity] = useState([]);
  let [getcity, setGetcity] = useState([]);
  const [validated, setValidated] = useState(false);
  const [imageName, setImageName] = useState('');
  const [userData, setUserData] = useState();
  const [countryIDName, setCountryIDName] = useState("");
  const [stateIDName, setStateIDName] = useState("");
  const [cityIDName, setCityIDName] = useState("");
  const [errorMessageImage, setErrorMessageImage] = useState(false)
  const [countryCode, setCountryCode] = useState('');
  const [inputDate, setInputDate] = useState();
  const [dateBirth, setDateBirth] = useState();

  const today = new Date();

  const [regForm, setRegForm] = useState({
    imageName: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    phoneNumber: '',
    countryCode: '',
    emailId: '',
    address: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
  });

  function sidebarfun() {
    setData(!data)
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValidated(true);

    // Check if dateOfBirth is empty
    if (regForm?.dateOfBirth === '') {
        alert("Date of Birth is required.");
        return;
    }

    // Check if imageName is empty
    if (regForm?.imageName === '') {
        toast.error("Please upload an image."); // Show error message
        return; // Prevent further execution
    }

    // Check zipCode and countryCode
    if (regForm?.zipCode?.length < 3 && regForm?.countryCode === '') {
        // Handle this case if needed
    } 
    else {
        try {
          
          const dataList = imageName ? [{
            // "crudOperationId": true,
            // "id": userId,
            "imageName": dataImage,
            "firstName": regForm.firstName,
            "lastName": regForm.lastName,
            "DateOfBirth": dayjs(regForm.dateOfBirth).format("YYYY-MM-DD"),
            "gender": regForm.gender,
            "phoneNumber": regForm.phoneNumber,
            "emailId": userData?.emailId,
            "address": regForm.address,
            "countryCode": regForm.countryCode,
            "country": location?.country|| countryIDName,
            "state": location?.['places'][0]['state'] || stateIDName,
            "city": location?.['places'][0]['place name'] || cityIDName,
            "zipCode": regForm.zipCode,
            "isPlayer": false
          }] : [{
            // "crudOperationId": true,
            // "id": userId,
            "imageName": regForm.imageName,
            "firstName": regForm.firstName,
            "lastName": regForm.lastName,
            "DateOfBirth": dayjs(regForm.dateOfBirth).format("YYYY-MM-DD"),
            "gender": regForm.gender,
            "phoneNumber": regForm.phoneNumber,
            "emailId": userData?.emailId,
            "address": regForm.address,
            "countryCode": regForm.countryCode,
            "country": location?.country|| countryIDName,
            "state": location?.['places'][0]['state'] || stateIDName,
            "city": location?.['places'][0]['place name'] || cityIDName,
            "zipCode": regForm.zipCode,
            "isPlayer": false
          }]
            const res = await WebAdminProfile(dataList);
            if (res?.status) {
                setValidated(false);
                toast.success(res?.message, { toastId: "success12" });
            } else {
                toast.error(res?.message, { toastId: "error12" });
            }
        } catch (error) {
            toast.error(error?.message);
        }
    }
};

  const handleFileChange = (event) => {
    setErrorMessageImage(false)
    setSelectedImage(URL.createObjectURL(event.target.files[0]));
    setImageName(event.target.files[0].name)
    const dataList = (event.target.files[0]);
    const imageAPI = async () => {
      const formDataImage = new FormData();
      formDataImage.append('file', dataList);
      await UploadImage(formDataImage).then((res) => {
        if (res?.data?.status) {
         
          setDataImage(res?.data?.message);
          setShowImage(true);

        } else {
          toast.error(res?.message);
        }
      })
    }

    imageAPI()
  };



  const isUser = JSON.parse(localStorage.getItem('isUser'));
  const userId = isUser?.userId;
  const isRole = localStorage.getItem('role');

  
  useEffect(() => {
    async function UserGet() {
      await PlayerGetById(userId).then((res) => {
        setUserData(res?.data?.imageName);

        setCountryIDName(res?.data?.country)
        setStateIDName(res?.data?.state);
        setCityIDName(res?.data?.city);
        setRegForm(res?.data);

      })
    }
    UserGet();
  }, [])




  useEffect(() => {
    if (isRole == "Manager") {
      const UserGet = async () => {
        await ManagerGetById().then((res) => {
          console.log( res, "fffff")
          setUserData(res?.data?.imageName);
          setCountryIDName(res?.data?.country)
          setStateIDName(res?.data?.state);
          setCityIDName(res?.data?.city);
          setRegForm(res?.data);
  
        });
      };
      UserGet();
    }
    else if (isRole == "Player") {
      const UserGet = async () => {
        await PlayerGetById().then((res) => {

          console.log( res, "ddsdd")
          setUserData(res?.data?.imageName);
          setCountryIDName(res?.data?.country)
          setStateIDName(res?.data?.state);
          setCityIDName(res?.data?.city);
          setRegForm(res?.data);

        });
      };
      UserGet();
    } else if (isRole == "Web Admin") {
      const UserGet = async () => {
        await ManagerGetById().then((res) => {

          setUserData(res?.data?.imageName);
          setCountryIDName(res?.data?.country)
          setStateIDName(res?.data?.state);
          setCityIDName(res?.data?.city);
          setRegForm(res?.data);

        });
      };
      UserGet();
    }

  }, [])


  useEffect(() => {
    if (regForm?.dateOfBirth) {
      const dateOfBirth = new Date(regForm?.dateOfBirth);
      const timezoneOffset = dateOfBirth.getTimezoneOffset();
      dateOfBirth.setMinutes(dateOfBirth.getMinutes() - timezoneOffset);
      setDateBirth(dateOfBirth);
    }
  }, [regForm]);











  const handelGender = (e) => {

    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value,
    },)
  }
  const handelform = async (e) => {
    setRegForm({
      ...regForm,
      [e.target.name]: e.target.value,
    },)

  };

  const handelPhone = async (e) => {

    setRegForm({
      ...regForm,
      phoneNumber: e,
    },)

  };
  const handelPhoneCountryCode = async (e) => {
    setCountryCode(e)
    setRegForm({
      ...regForm,
      countryCode: e,
    },)
  };
  const handelDate = async (e) => {
    setRegForm({
      ...regForm,
      dateOfBirth: e,
    })
    setInputDate(e)
  };

  const shouldDisableDate = (date) => {
    return date > today - 18 * 365;
  };

  const navigate = useNavigate();
  const cancelbtn = (e) => {
    navigate(-1)
  }

  let dateString = regForm?.dateOfBirth;
  let date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    let localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    var formattedDateOfBirth = localDate.toISOString().split('T')[0];
    let parts = formattedDateOfBirth.split('-');

    var formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
  
  } else {
    console.error('Invalid date format');

  }
  const handleKeypress = (e) => {
    const characterCode = e.key;
    if (characterCode === 'Backspace') return;

    const characterNumber = Number(characterCode);
    if (characterNumber >= 0 && characterNumber <= 9) {
      if (e.currentTarget.value && e.currentTarget.value.length) {
        return;
      } else if (characterNumber === 0) {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  }


  const [location, setLocation] = useState(null);
  const [error, setError] = useState('');

  const handelZipCode = async (e) => {
    const zipCode = e.target.value;
    setRegForm({
      ...regForm,
      [e.target.name]: zipCode,
    },)
    if (zipCode.length == 5) {
      try {
        const response = await fetch(`https://api.zippopotam.us/us/${zipCode}`);
        if (!response.ok) {
          throw new Error('Zip code not found');
        }
        const data = await response.json();
        setLocation(data);
        setError('');
      } catch (err) {
        setError(err.message);
        setLocation(null);
      }
    }
  }


  const phoneNumber = parsePhoneNumberFromString(String(regForm?.phoneNumber), 'US');
  const formattedNumber = phoneNumber ? phoneNumber.formatNational() : regForm?.phoneNumber;


  return (
    <>
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group" >
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <Row>
            <Col>  <h1 className="heading-dash">Profile</h1></Col>
          </Row>

          <Card className='p-5 profile-sec'>
            <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}>


              <Row>
                <Col md={4} lg="4">
                  <div className='text-center editprofileimage'>

                    {showImage == false ? <div>
                      {userData == null ? <img src="../image/profileDefault.png"></img> :
                        <img src={ImageUrl + userData} ></img>

                      }

                      <label for="file" className='editprofileicon' name="file" onChange={handleFileChange}  >
                        <icons.Pencil />

                        <Form.Control type="file" id="file" multiple="" data-original-title="upload photos" required />

                        {
                          regForm?.imageName===''?<Form.Control.Feedback className='image-upload-update' type="invalid">
                          Please upload image.
                        </Form.Control.Feedback>:""
                        }
                        

                      </label>

                    </div> :

                      <div>

                        {selectedImage && <img src={selectedImage} alt="Selected" required />}
                        <label for="file" className='editprofileicon' name="file" onChange={handleFileChange} required>
                          <icons.Pencil />
                          <Form.Control type="file" id="file" multiple="" data-original-title="upload photos" required />

                        </label>

                      </div>
                    }
                    <p className='mt-2 image-upload-mess'>(jpeg, jpg, png and size upto 2 mb)<span className='text-danger'>*</span></p>


                  </div>

                </Col>
                <Col md={7} className='border-custom profile-form-sec'>

                  <Row className="mb-3">

                    <Form.Group as={Col} md="6 mt-3" controlId="validationCustom02">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control onChange={handelform} name="firstName" value={regForm?.firstName} required type="text" placeholder="Enter your first name" onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }} />
                      <Form.Control.Feedback type="invalid">
                        Please enter First Name.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="6 mt-3" controlId="validationCustomUsername">
                      <Form.Label>Last Name</Form.Label>
                      <InputGroup hasValidation>

                        <Form.Control
                          type="text"
                          onChange={handelform} name="lastName" value={regForm?.lastName}
                          placeholder=" Enter your last name"
                          aria-describedby="inputGroupPrepend"
                          onKeyPress={(e) => { if (e.key === 'Enter') e.preventDefault(); }}
                        />

                      </InputGroup>
                    </Form.Group>



                    {/* <Form.Group as={Col} md="6 mt-3">
                      <Form.Label>Date of Birth</Form.Label>
               
                      <DatePicker placeholder="Select Date of Birth" shouldDisableDate={shouldDisableDate} format="MM/dd/yyyy" value={dateBirth} name="dateOfBirth" className=' Auction-Date' onChange={handelDate} required />
                      
                      <Form.Control.Feedback type="invalid">
                        Please enter Date of birth.
                      </Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.Group as={Col} md="6 mt-3" controlId="formDateOfBirth">
                      <Form.Label>Date of Birth</Form.Label>
                      <DatePicker 
                          placeholder="Select Date of Birth" 
                          shouldDisableDate={shouldDisableDate} 
                          format="MM/dd/yyyy" 
                          value={dateBirth} 
                          name="dateOfBirth" 
                          className='Auction-Date' 
                          onChange={handelDate} 
                          required 
                      />
                      
                      <Form.Control.Feedback type="invalid" show={validated && !dateBirth}>
                          Please enter Date of birth.
                      </Form.Control.Feedback>
                  </Form.Group>

                    <Form.Group as={Col} md="6 mt-3" className="text-left">
                      <Form.Label>Gender</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Select onChange={handelGender} name="gender" value={regForm?.gender} className='select-option' required as="select">
                          <option value={''} >Select Gender</option>
                          <option value={"M"}>Male</option>
                          <option value={"F"} >Female</option>
                          <option value={"O"} >Other</option>

                        </Form.Select >
                        <Form.Control.Feedback type="invalid">
                          Please select a Gender.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>






                  

                    <Form.Group as={Col} md="6 mt-3" >
                      <Form.Label>Phone Number</Form.Label>
                      <div className='d-flex group-ph'>
                        <PhoneInput type="text"
                          international
                          value={countryCode || `+${regForm?.countryCode}`}
                          onChange={handelPhoneCountryCode}
                          flags={flags}
                          countryCallingCodeEditable={false}
                        />

                        <Form.Control type="text" maxLength="10" onKeyDown={handleKeypress} className='phone-number-input' pattern="[1-9]{1}[0-9]{9}" onChange={handelform} name="phoneNumber" value={formattedNumber} placeholder="Enter your phone number" required />
                      </div>


                      <Form.Control.Feedback type="invalid">
                        Please enter phone number.
                      </Form.Control.Feedback>
                    </Form.Group>



                    <Form.Group as={Col} md="6 mt-3" >
                      <Form.Label>Email/User Id</Form.Label>
                      <Form.Control type="email" value={regForm?.emailId} disabled placeholder="Enter your email" required />
                      <Form.Control.Feedback type="invalid">
                        Please enter Email.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="12 mt-3" >
                      <Form.Label>Address</Form.Label>
                      <Form.Control type="text" onChange={handelform} name="address" value={regForm?.address} placeholder="Enter your Address" required />
                      <Form.Control.Feedback type="invalid">
                        Please enter Address.
                      </Form.Control.Feedback>
                    </Form.Group>


                    <Form.Group as={Col} md="6 mt-3" className="text-left" controlId="validationCustomCountry">
                      <Form.Label>Country</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control type="text" onChange={handelform} name="country" value={location?.country || regForm?.country} placeholder="Enter your Address" required />
                        <Form.Control.Feedback type="invalid">
                          Please Enter a Country.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md="6 mt-3" className="text-left" controlId="validationCustomState">
                      <Form.Label>State</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control type="text" onChange={handelform} name="state" value={location?.['places'][0]['state'] || regForm?.state} placeholder="Enter your Address" required />
                        <Form.Control.Feedback type="invalid">
                          Please Enter a State/Province.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md="6 mt-3" className="text-left" >
                      <Form.Label>City</Form.Label>
                      <InputGroup hasValidation>

                        <Form.Control type="text" onChange={handelform} name="city" value={location?.['places'][0]['place name'] || regForm?.city} placeholder="Enter your Address" required />
                        <Form.Control.Feedback type="invalid">
                          Please Enter a City name.
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <Form.Group as={Col} md="6 mt-3" >
                      <Form.Label>Zip/Province Code</Form.Label>
                      <Form.Control type="text" minLength={3} maxLength="5" onChange={(e) => {
                        handelZipCode(e);
                      }} name="zipCode" value={regForm?.zipCode} placeholder="Enter your Zip Code" required />
                      <Form.Control.Feedback type="invalid">
                        Please enter Zip Code.
                      </Form.Control.Feedback>
                    </Form.Group>







                  </Row>

                  <button type="button" className='cancel-btn mt-3' onClick={cancelbtn}>Cancel</button>

                  <Button type="submit" className='update-btn-prof '>Update</Button>
                </Col>
              </Row>
            </Form>
          </Card>

        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>
    </>
  )
}
