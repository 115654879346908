import React, { useEffect, useRef, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import Sidebar from '../../CommonComponent/sidebar/Sidebar'
import { Button, Col, Form, InputGroup, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuctionBYIdGet, AuctionGetTeam, BidReg, BidAuctionTeamWinner, UserGetId, getBIdCount, RefreshToken, BidStatus, AuctionAll } from '../../redux/auth-reducer/auth-service'
import { ToastContainer, toast } from 'react-toastify';
import dayjs from 'dayjs'
import Modal from 'react-bootstrap/Modal';
import soundFile from './sound.mp3';
import soundFileBid from './sound-bid.mp3';
import * as signalR from "@microsoft/signalr";
import { SignalRUrl } from '../../utils/config/config'

export const PlaceBid = () => {

    const [data, setData] = useState(false);
    const [validated, setValidated] = useState(false);
    const [auctionList, setAuctionList] = useState([]);
    const [bidCountList, setBidCountList] = useState();
    const [userData, setUserData] = useState();
    const [oldBidAmount, setOldBidAmount] = useState();
    const [errorMessage, setErrorMessage] = useState(false)
    const [auctionIdData, setAuctionIdData] = useState();
    const [teamList, setTeamList] = useState();
    const [disabled, setDisabled] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const { state } = useLocation()
    const [minutes, setMinutes] = useState(0);
    const [CurrentBidAmount, setCurrentBidAmount] = useState();
    const [seconds, setSeconds] = useState(0);
    const [show, setShow] = useState(false);
    const [bidWinner, setBidWinner] = useState([]);
    const intervalRef = useRef(null);
    const [signalRData, setSignalRData] = useState([]);
    const [hubConnection, setHubConnection] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [bidStatusAuction, setBidStatusAuction] = useState();
    const [currentTotalPlaceBId, setCurrentTotalPlaceBId] = useState();

    // useEffect(() => {

    //     if (minutes === 0 && seconds == 0) {

    //         const audio = new Audio(soundFile);
    //         audio.play().catch(error => {
    //             console.error("Error playing sound:", error);
    //         });
    //     }

    // }, [minutes, seconds]);


    useEffect(() => {
        let audio;
        if (minutes === 0 && seconds === 0) {
            audio = new Audio(soundFile);
            audio.play().catch(error => {
                console.error("Error playing sound:", error);
            });

            // Stop the sound after 3 seconds
            const timeoutId = setTimeout(() => {
                audio.pause();
                audio.currentTime = 0;
            }, 3000); // 3000 milliseconds = 3 seconds


            return () => {
                clearTimeout(timeoutId);
                if (audio) {
                    audio.pause();
                    audio.currentTime = 0;
                }
            };
        }
    }, [minutes, seconds]);



    useEffect(() => {
        let audioBId;
        if (minutes === 0 && seconds === 3) {
            audioBId = new Audio(soundFileBid);
            audioBId.play().catch(error => {
                console.error("Error playing sound:", error);
            });

            // Stop the sound after 3 seconds
            const timeoutId = setTimeout(() => {
                audioBId.pause();
                audioBId.currentTime = 0;
            }, 8000); // 3000 milliseconds = 3 seconds


            return () => {
                clearTimeout(timeoutId);
                if (audioBId) {
                    audioBId.pause();
                    audioBId.currentTime = 0;
                }
            };
        }
    }, [minutes, seconds]);




    useEffect(() => {
        const storedState = JSON.parse(localStorage.getItem('timerState'));
        if (storedState) {
            setMinutes(storedState.minutes);
            setSeconds(storedState.seconds);
        }
    }, []);


    // useEffect(() => {
    //     if (minutes === 0 && seconds === 0) {
    //         handleShow()
    //     }
    // }, [minutes, seconds])


    const handleClose = () => navigate("/player/AuctionPlayer");


    const [regForm, setRegForm] = useState({
        bidId: 0,
        auctionId: 0,
        teamId: Number,
        startingBid: 0,
        bidTime: 0,
        totalPlacedBids: 0,
        bidIncrement: 0,
        currentBidAmount: 0,
        bidAmount: 0,
        bidPlacedBy: 0
    });

    const navigate = useNavigate()

    const isUser = JSON.parse(localStorage.getItem('isUser'));
    const userId = isUser.userId;

    useEffect(() => {
        async function UserGet() {
            await UserGetId().then((res) => {
                setUserData(res?.id);

            })
        }
        UserGet();
    }, []);
    const sidebarfun = () => {
        setData(!data)
    }

    async function auctionTeamWinner(auctionId, teamId) {
        await BidAuctionTeamWinner(auctionId, teamId).then((res) => {
            if (res.data && res.data.length > 0) {
                setBidWinner(res.data[0].teamOwnerId)
            }

        })
    }



    const handleSubmit = async (event) => {
        event.preventDefault();
        if (currentTotalPlaceBId === undefined) {

            const registerData = [{
                // "crudOperationId": true,
                // "bidId": 0,
                "teamId": Number(state?.teamId),
                "auctionId": state?.auctionId,
                "startingBid": state?.startingBid,
                "bidTime": state?.bidTime,
                "totalPlacedBids": state?.totalPlacedBids,
                "bidIncrement": state?.bidIncrement,
                "currentBidAmount": Number(state?.startingBid),
                "bidAmount": state?.startingBid,
                // "bidPlacedBy": Number(userId),
            }]
            console.log(registerData, "if???????")
            await BidReg(registerData).then((res) => {

                if (res?.data?.status == true) {
                    setErrorMessage(false)

                    toast.success(res?.data?.message);
                    setDisabled(true);
                        navigate('/Player/Bid');

                }
                else {
                    toast.error(res?.data?.message);
                }
            })
        }
        // if (state?.bidIncrement + state?.startingBid > oldBidAmount) {
        //     setErrorMessage(true)
        // }
        else {
            const registerData = [{
                // "crudOperationId": true,
                // "bidId": 0,
                "teamId": Number(currentTotalPlaceBId?.teamId),
                "auctionId": currentTotalPlaceBId?.auctionId,
                "startingBid": currentTotalPlaceBId?.startingBid,
                "bidTime": currentTotalPlaceBId?.bidTime,
                "totalPlacedBids": currentTotalPlaceBId?.totalPlacedBids,
                "bidIncrement": currentTotalPlaceBId?.bidIncrement,
                // "currentBidAmount": Number(currentTotalPlaceBId?.bidIncrement + currentTotalPlaceBId?.startingBid),
                "currentBidAmount": Number(currentTotalPlaceBId?.currentBidAmount),
                "bidAmount": Number(oldBidAmount) || currentTotalPlaceBId?.currentBidAmount,
                "bidPlacedBy": Number(userId),
            }]
            console.log(registerData, "else???????")

            await BidReg(registerData).then((res) => {

                if (res?.data?.status == true) {

                    toast.success(res?.data?.message);
                    setDisabled(true);
                        navigate('/Player/Bid');

                }
                else {
                    toast.error(res?.data?.message);
                }
            })
        }

    };

    useEffect(() => {

        const AuctionID = async () => {
            await AuctionBYIdGet(state).then((response) => {
                setAuctionIdData(response?.auctionId)
                setAuctionList(response);
                setCurrentBidAmount(response?.bidIncrement + response?.minBid)
                setOldBidAmount(response?.bidIncrement + response?.minBid);
            })
        }
        AuctionID();

    }, [])

    useEffect(() => {

        const GetTeamList = async () => {
            await AuctionGetTeam(auctionIdData).then((response) => {
                setTeamList(response?.data)

            })
        }
        GetTeamList();

    }, [auctionIdData])

    useEffect(() => {

        const BidCount = async () => {
            await getBIdCount().then((response) => {
                setBidCountList(response);

            })
        }
        BidCount();

    }, [])

    var customId = [1, 2, 5, 6]

    const handelBid = (e) => {
        setRegForm({
            ...regForm,
            [e.target.name]: e.target.value
        }
        )
    }

    const handelBidAmount = (e) => {
        setErrorMessage(false);
        const value = e.target.value;
        if (value >= 0) {
            setInputValue(value);
        }
        if (e.target.value.length > 6) {
            window.alert("Bid amount should not exceed 6 digits");
        }

        setDisabled(isNaN(value));
        setOldBidAmount(e.target.value);
    }
console.log("currentTotalPlaceBId", currentTotalPlaceBId)



    useEffect(() => {
        const updateTime = (time = "") => {
            console.log("time:::", time)
            // if(!time) return
            const [minutesStr, secondsStr] = time?.split(':') ?? [0 , 0];
            const updatedMinutes = parseInt(minutesStr, 10);
            const updatedSeconds = parseInt(secondsStr, 10);

            // Only set state if values are valid
            if (!isNaN(updatedMinutes) && !isNaN(updatedSeconds)) {
                setMinutes(updatedMinutes);
                setSeconds(updatedSeconds);
                console.log("updatedMinutes === 0 && updatedSeconds", updatedMinutes , updatedSeconds)
                if (updatedMinutes === 0 && updatedSeconds === 0) {
                    handleShow(); // Trigger any action on zero
                }
                if (updatedMinutes === 0 && updatedSeconds === 0) {
                    setDisabled(true)
                }
            }
        };

        // Manage bid time initialization logic
        if (currentTotalPlaceBId === undefined) {
            if (state.remaingBidTime === "00:01") {
                setMinutes(state.bidTime);
                console.log("time one")

            }
            else {
                console.log("time update")
                updateTime(state.remaingBidTime);
            }
        } else {
            if (currentTotalPlaceBId.remaingBidTime === "00:01") {
                // setMinutes(state.bidTime);
                console.log("time state ", state)
                console.log("time currentTotalPlaceBId", currentTotalPlaceBId)
                console.log("time three")
            } else {
                updateTime(currentTotalPlaceBId.remaingBidTime);
                console.log("time four")

            }
        }


        if (currentTotalPlaceBId?.remaingBidTime === '00:00') {
            hubConnection.stop().then(() => {
                console.log("SignalR connection stopped");
            }).catch((error) => {
                console.error("Error stopping SignalR connection:", error);
            });
        }

    }, [state, currentTotalPlaceBId, hubConnection]);


    const handleShow = () => {
        if (!show) {
            setShow(true);
            auctionTeamWinner(state.auctionId, state.teamId)
        }
    };



    useEffect(() => {
        if (state.bidTime == 0.5) {
            if (minutes === 0 && seconds === 0) {
                clearInterval(intervalRef.current);
            } else {
                intervalRef.current = setInterval(() => {
                    if (seconds === 0) {
                        if (minutes === 0) {
                            clearInterval(intervalRef.current);
                        } else {
                            setMinutes(0);
                            setSeconds(29);
                        }
                    } else {
                        setSeconds((prevSeconds) => prevSeconds - 1);
                    }
                }, 1000);
                return () => {
                    clearInterval(intervalRef.current);
                };
            }
        } else {
            if (minutes === 0 && seconds === 0) {
                clearInterval(intervalRef.current);
            } else {
                intervalRef.current = setInterval(() => {
                    if (seconds === 0) {
                        if (minutes === 0) {
                            clearInterval(intervalRef.current);
                        } else {
                            setMinutes((prevMinutes) => prevMinutes - 1);
                            setSeconds(59);
                        }
                    } else {
                        setSeconds((prevSeconds) => prevSeconds - 1);
                    }
                }, 1000);
                return () => {
                    clearInterval(intervalRef.current);
                };
            }
        }
    }, [minutes, seconds, state]);




    useEffect(() => {
        const storedState = JSON.parse(localStorage.getItem('timerState'));
        if (storedState) {
            setMinutes(storedState.minutes);
            setSeconds(storedState.seconds);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('timerState', JSON.stringify({ minutes, seconds }));
    }, [minutes, seconds]);

    useEffect(() => {
        return () => {
            localStorage.removeItem('timerState');
        };
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('timerState')) {
            setMinutes(5);
            setSeconds(0);
        }
    }, []);








    /***************** */

    useEffect(() => {
        const BidStatusAll = async () => {
            await AuctionAll().then((res) => {
                setBidStatusAuction(res?.data);
                res?.data.map((item) => {
                    // if (state?.totalPlacedBids === 0) {
                    //     console.log("log 0 place bid")
                    // }
                    // else {
                    if (item.auctionId === state.auctionId && item.teamId === state.teamId) {
                        setCurrentTotalPlaceBId(item)
                    }
                    // }


                })
            });
        }
        BidStatusAll()
    }, [])





    useEffect(() => {
        const createHubConnection = async () => {
            let token = localStorage.getItem('isToken');
            const hubConnect = new signalR.HubConnectionBuilder()
                .withUrl(SignalRUrl, {
                    // https://gadienterprises-gamc-qa.chetu.com/hubs
                    // https://staging.xcutta.com/backend/hubs
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                    accessTokenFactory: () => token,
                })
                .withAutomaticReconnect()
                .build();

            const handleTokenExpiration = async () => {
                try {
                    const newToken = await RefreshToken(); // Assume this function returns a new token
                    token = newToken?.data?.token;
                    hubConnect.accessTokenFactory = () => token; // Update token factory
                } catch (error) {
                    console.error("Failed to refresh token:", error);
                }
            };

            try {
                await hubConnect.start(); // Try to start the connection
                console.log("SignalR Connection Established");
            } catch (err) {
                console.error("SignalR connection failed at first attempt:", err);
                // Attempt to refresh token and retry connection
                await handleTokenExpiration();
                try {
                    await hubConnect.start(); // Retry starting the connection
                    console.log("SignalR Connection Established after refreshing token");
                } catch (retryErr) {
                    console.error("SignalR connection failed after retry:", retryErr);
                }
            }

            hubConnect.onclose(async (error) => {
                console.error("SignalR connection closed. Error:", error);
                await new Promise(resolve => setTimeout(resolve, 5000));
                createHubConnection();
            });

            setHubConnection(hubConnect);
        };

        createHubConnection();

        return () => {
            if (hubConnection) {
                hubConnection.stop()
                    .then(() => console.log("SignalR Connection Stopped"));
            }
        };
    }, []);

    useEffect(() => {
        if (hubConnection) {
            // Listen for messages from the SignalR hub
            hubConnection.on("ReceiveBidStatuses", (data) => {
                console.log("Received Bid Statuses:", data);
                data.map((item) => {
                    console.log(data, "datatatta")
                    // if (state?.totalPlacedBids === 0) {
                    //     console.log("log 0 place bid")
                    // }
                    // else {
                    if (item.auctionId === state.auctionId && item.teamId === state.teamId) {
                        setCurrentTotalPlaceBId(item)
                    }
                    // }


                })
                setSignalRData(data);
            });

            return () => {
                hubConnection.off("ReceiveBidStatuses");
            };
        }
    }, [hubConnection]);

    // if(currentTotalPlaceBId?.remaingBidTime==='00:00'){

    // }

    console.log(currentTotalPlaceBId, "currentTotalPlaceBId????????????????")
    // console.log(currentTotalPlaceBId, "currentTotalPlaceBId")



    return (
        <>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Bid Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        bidWinner === userId ? (<h4><span className='text-success'>Congratulations,</span>You won the auction</h4>) : (<h4>Sorry, You lost the auction</h4>)
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Ok
                    </Button>

                </Modal.Footer>
            </Modal>

            <TopNavbar sidebarfun={sidebarfun} />
            <div className="dasboard-group" >
                <Sidebar data={data} />
                <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>

                    <Row>
                        <Col md={6}><h1 className="heading-dash">  Place Bid </h1></Col>
                    </Row>
                    <div className='group_data_table p-3'>
                        {/* <h2 className='currentBid text-center'>Highest Bid {state?.totalPlacedBids == 0 ? 0 : state?.startingBid}</h2> */}
                        <h2 className='currentBid text-center'>Total Pool : {currentTotalPlaceBId == undefined ? state?.totalPoolAmount : currentTotalPlaceBId?.totalPoolAmount}</h2>

                        <Row>
                            <Col md={6}>

                                <Form noValidate validated={validated} >
                                    <Form.Group as={Col} md="12 mt-3" className="text-left " controlId="validationCustomCountry">
                                        <Form.Label>Team</Form.Label>

                                        <InputGroup hasValidation>

                                            <Form.Control
                                                type="text"
                                                name="teamId"
                                                disabled
                                                value={currentTotalPlaceBId == undefined ? state?.teamName : currentTotalPlaceBId?.teamName}
                                                style={{ height: "46px" }}
                                                placeholder="Team Name"
                                                aria-describedby="inputGroupPrepend"
                                                required
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                Please a select Team name.
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                    <Row>

                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">

                                            <Form.Label className="mt-3">  {state?.totalPlacedBids == 0 ? "Start Bid" : "Last Bid"}</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="minBid"
                                                    disabled
                                                    value={currentTotalPlaceBId == undefined ? state?.startingBid : currentTotalPlaceBId?.startingBid}
                                                    style={{ height: "46px" }}
                                                    placeholder="Enter your Minimum Bid"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter a Minimum Bid.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">

                                            <Form.Label className="mt-3">Bid Time Limit</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="bidTimeLimit"
                                                    style={{ height: "46px" }}
                                                    disabled
                                                    value={currentTotalPlaceBId == undefined ? state?.bidTime : currentTotalPlaceBId?.bidTime}
                                                    placeholder="Enter your Bid Time Limit"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter a Bid Time Limit.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">

                                            <Form.Label className="mt-3">Total Placed Bids</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="bidPlacedBy"
                                                    style={{ height: "46px" }}
                                                    disabled
                                                    // value={state?.totalPlacedBids}
                                                    value={currentTotalPlaceBId == undefined ? state?.totalPlacedBids : currentTotalPlaceBId?.totalPlacedBids}
                                                    placeholder="Enter your Placed Bids"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter a Placed Bids.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">

                                            <Form.Label className="mt-3">Minimum Bid Increment</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name="bidIncrement"
                                                    style={{ height: "46px" }}
                                                    disabled
                                                    value={state?.bidIncrement}

                                                    placeholder="Enter your Minimum Bid Increment"
                                                    aria-describedby="inputGroupPrepend"
                                                    required
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Please Enter a Minimum Bid Increment.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        <Form.Label className="mt-3">Minimum Next Bid </Form.Label>
                                        <h2 className='currentBid'>
                                            {/* {state?.totalPlacedBids === 0 ? state?.startingBid : currentTotalPlaceBId?.startingBid + state?.bidIncrement} */}
                                            {currentTotalPlaceBId?.currentBidAmount}
                                            {/* {currentTotalPlaceBId == undefined ? state?.startingBid : currentTotalPlaceBId.startingBid + state?.bidIncrement} */}
                                        </h2>

                                        <Form.Group as={Col} md="12">
                                            {/* {
                                                currentTotalPlaceBId?.startingBid===NaN ?"": <Button type='button' disabled={disabled} onClick={handleSubmit} className='update-btn-prof mt-3 ms-0'>Place My Bid</Button>
                                            } */}

                                            {
                                                isNaN(currentTotalPlaceBId?.startingBid) ? "" : <Button type='button' disabled={disabled} onClick={handleSubmit} className='update-btn-prof mt-3 ms-0'>Place My Bid</Button>
                                            }

                                           
                                        </Form.Group>

                                        <Form.Group as={Col} md="6 mt-3" controlId="validationCustomFristname">
                                            <Form.Label className="mt-3">Place Custom Bidding Amount</Form.Label>
                                            <InputGroup hasValidation>
                                                <Form.Control
                                                    type="number"
                                                    style={{ height: "46px" }}
                                                    name="bidAmount"
                                                    minLength={6}
                                                    onChange={handelBidAmount}
                                                    placeholder="Enter Bid Amount"
                                                    aria-describedby="inputGroupPrepend"
                                                    inputProps={{ maxLength: 6 }}
                                                />

                                            </InputGroup>

                                        </Form.Group>

                                        {errorMessage == true ? <p className='err-message'>
                                            Bid Amount should be equal and greater than Minimum Next Bid.
                                        </p> : ""}
                                        <p className='mt-2'>    All Bidding Amount is in USD</p>

                                    </Row>
                                </Form>

                                <div className='border-right'></div>
                            </Col>
                            <Col md={6}>
                                <div className='placeBid-sec'>
                                    <h2 className='text-white'>
                                        {state?.auctionName}
                                        - {state?.teamName}
                                    </h2>

                                    <div className='group-auctionBid-date'>
                                        {minutes === 0 && seconds === 0 ? (
                                            <Col md={12}>
                                                <p>Bidding Ended</p>
                                            </Col>
                                        ) : (
                                            <Row>
                                                <Col md={12}>
                                                    <span style={{ color: minutes === 0 && seconds <= 10 ? 'red' : 'white' }}>
                                                        {minutes?.toString().padStart(2, '0')}:{seconds?.toString().padStart(2, '0')}
                                                    </span>
                                                </Col>
                                            </Row>
                                        )}
                                    </div>
                                    <img src="../image/auctionImage.png" className='ImageAuction_bid' />
                                </div>
                            </Col>
                        </Row>

                    </div>

                </div>
            </div>
        </>
    )
}


