import React from 'react'
import { AnnouncementData } from "../../redux/auth-reducer/auth-service";
import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

export const ExpandedComponent = ({ data }) => {
  const [announcements, setAnnouncements] = useState([]);



  useEffect(() => {
    const AnnouncementDataFunc = async () => {
      await AnnouncementData(data.auctionId).then((response) => {
        setAnnouncements(response?.data);
        console.log(response, "data list")

      });
    };
    AnnouncementDataFunc();
  }, []);





  return (
    <div className='innerdiv'>

      <div style={{ color: "#fff", backgroundColor: "blue" }} >

      </div>

      <div className='p-3'>

        <Table striped bordered hover size="sm" className='text-center'>
          <thead>
            <tr>

              <th>Team Name</th>
              <th>Winning Bidder</th>
              <th >Bid Amount</th>
            </tr>
          </thead>
          <tbody>
            {
              announcements?.announcementData?.map((item) => (
                <tr>
                  <td>{item?.teamName}</td>
                  <td>{item?.playerName == null ? "-" : item?.playerName}</td>
                  <td className='text-payout'>{item?.maxAmount}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>

        <div>
          <span style={{ fontWeight: "600" }}>Contest Winner Details</span>
          <br />
          <br />

          <Table striped bordered hover size="sm" className='text-center'>
            <thead>
              <tr>
                <th>Winning Team</th>
                <th>Winner %</th>
                <th>Winner Amount</th>
                <th>Position</th>

              </tr>
            </thead>
            <tbody>
              {

                announcements?.winnerData?.map((item) => (
                  <tr>

                    <td className='text-payout'>{item.winnerTeamName == "" ? "To be declared" : item?.winnerTeamName}</td>
                    <td>{item?.winnerPercentage}</td>
                    <td>{item?.winnerAmount}</td>
                    <td>{item?.matchLevel}</td>
                  </tr>
                ))

              }

            </tbody>
          </Table>

        </div>

      </div>
    </div>
  )
}
