import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import imageCopy from '../../assets/images/logo-2.png'
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Col, Dropdown, Form, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { GetToken, RemovRoles, RemoveRefreshToken, RemoveToken, RemoveUser } from '../../utils/helper/helper';
import { GhostButton, ResponsiveNavbar } from 'react-hamburger-menus';
import "react-hamburger-menus/dist/style.css";

function Headertwo() {
  const [selectedOption, setSelectedOption] = useState('UserRegistration');
  const [tokenItem, setTokenItem] = useState()
  const [smShow, setSmShow] = useState(false);
  const [userData, setUserData] = useState();
  const [item, setItem] = useState(window.location.pathname);
  const navigate = useNavigate();
  const handleClose = () => setSmShow(false);
  const handleShow = () => setSmShow(true);
  function subBtn() {
    if (selectedOption === 'UserRegistration') {
      navigate('/UserRegistration')
    } else if (selectedOption === 'Organization') {
      navigate('/Organization')
    }

  }
  const close = () => {
    setSmShow(false)
  }
  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };



  useEffect(() => {
    setTokenItem(GetToken())
  }, []);

  async function logout() {
    RemoveToken();
    RemoveRefreshToken();
    RemoveUser();
    RemovRoles()
    localStorage.removeItem('Role');
    navigate("/");
    window.location.reload();
  }

  const handleActiveStatus = () => {
    setItem(window.location.pathname);
   
  };

  const roles = localStorage.getItem("role")

  const isUser = JSON.parse(localStorage.getItem('isUser'));


  const handleLandingPage = () => {
    if (localStorage.getItem("role") == "Manager") {
      navigate('/SuperAdmin/SuperDashboard');

    } else if (localStorage.getItem("role") == "Player") {
      navigate('/Player/Player_dashboard');

    } else {
      navigate('/WebAdmin/Dashboard');
    }

  }


  return (
    <>
<div className='container-fluid'>
    
<nav className='header navbar navbar-expand-lg navbar-light'>
    <ResponsiveNavbar className="header"
    logo={<><Link to="/Home"><img src="../image/logo1.png" className='landing-header-logo'/></Link></>}
  
  >
      <ul>
        <li> <Link to="/Home" className={`nav-link ${item == "/" || item == "/Home" ? " active" : ""}`} onClick={() => handleActiveStatus()}>Home</Link></li>
        <li> <Link to="/AboutUs" className={`nav-link ${item == "/AboutUs" ? " active" : ""}`} onClick={() => handleActiveStatus()}>About Us</Link></li>
        <li> <Link to="/Works" className={`nav-link ${item == "/Works" ? " active" : ""}`} onClick={() => handleActiveStatus()}>How it Works</Link></li>
        <li> <Link to="/ManagerTips" className={`nav-link ${item == "/ManagerTips" ? " active" : ""}`} onClick={() => handleActiveStatus()}>Manager Tips</Link></li>
      <li><Link to="/Faq" className={`nav-link ${item == "/Faq" ? " active" : ""}`} onClick={() => handleActiveStatus()}>FAQ</Link></li>
      <li> <Link to="/ContactUs" className={`nav-link ${item == "/ContactUs" ? " active" : ""}`} onClick={() => handleActiveStatus()}>Contact Us</Link></li>
      {
                tokenItem == null ? <>
                  <li><Link to="/login"><Button variant="outline-success">Login </Button></Link></li>
                  <li><Button variant="primary" onClick={handleShow}>Sign Up</Button></li>
                </> : <>
                <Button variant='primary' onClick={handleLandingPage} style={{ fontSize: "15px", padding: "5px 5px", marginRight: "15px" }}>Dashboard</Button>
                <li>
                <Dropdown>
                
                <Dropdown.Toggle variant="none" className='d-flex align-items-center p-0 '>

                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip1">

                    {isUser.fullName}
                  </Tooltip>}>
                    <div className='text-hide user-list-item'>{isUser.fullName}</div>
                  </OverlayTrigger>
                </Dropdown.Toggle>
                <Dropdown.Menu>

                  <Dropdown.Item onClick={logout} >
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
                </li>
                </>
              }
     
      </ul>
      
     

  </ResponsiveNavbar>
  </nav>
  </div>
      <Modal show={smShow} onHide={handleClose} centered>


        <img src="../image/circle_full_width.png" width="100%" height={80} />

        <Modal.Body className='pt-0'>
          <h2 className='text-center heading-get-started'><b>Get Started Now</b></h2>
          <p className='text-center mb-4 mt-3'>Create an Account as</p>

          <div className='grop-input-radio d-flex justify-content-center'>
            <Form.Check className="me-3" label=" Player" type="radio" name="flexRadioDefault" value="UserRegistration"
              checked={selectedOption === 'UserRegistration'} onChange={handleOptionChange} id="flexRadioDefault"
            />

            <Form.Check label="Xcutta Manager" type="radio" name="flexRadioDefault" value="Organization" checked={selectedOption === 'Organization'}
              onChange={handleOptionChange} id="flexRadioDefault2"
            />
          </div>
          <Row>

            <Col md={12} className='text-center mt-4 pb-5'>
              <Button className='text-center submit-btn' type="submit" onClick={subBtn}>Continue</Button>
              <Button className='text-center btn btn-danger ms-3 ' onClick={close} type="submit"  >Close</Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Headertwo;