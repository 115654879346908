import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar'
import Sidebar from '../../CommonComponent/sidebar/Sidebar'
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';
import { Form } from "react-bootstrap";
import { Footer } from '../../Footer';
import { ChartAdd2 } from '../Outer/ChartAdd2';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { AuctionAll, AuctionCount, BidAll, BidWinnerList, GetBidAmountReport, GetBidCountDetail, GetReportData } from '../../redux/auth-reducer/auth-service';
import { ImageUrl } from '../../utils/config/config';
import dayjs from 'dayjs';
import { DatePicker } from 'rsuite';
export const Player_dashboard = () => {
  const [data, setData] = useState(false);
  const navigate = useNavigate();
  const [itemList, setItemList] = useState([]);
  const [itemListRecent, setItemListRecent] = useState([]);
  const [itemDataBidWin, setItemDataBidWin] = useState([]);
  const [dataBidWindLn, setDataBidWindLn] = useState();
  const [itemCountData, setItemCountData] = useState();
  const [activeTab, setActiveTab] = useState("BidAlll");
  const [bidWonAmount, setBidWonAmount] = useState("");
  const [investedBiddingAmount, setInvestedBiddingAmount] = useState("");

  const [countvalue, setCountValue] = useState({
    bidcount: "",
    auctioncount: ""
  });
  function sidebarfun() {
    setData(!data)
  }
  useEffect(() => {
    const isAuth = localStorage.getItem('role');
    if (isAuth == 'Player') {

    } else {
      navigate("/Login")
    }

  }, [])

  useEffect(() => {
    const AuctionAllFn = async () => {
      await AuctionAll().then((response) => {

        setItemList(response?.data?.data?.dataList);
      })
    }
    AuctionAllFn();

  }, []);

  useEffect(() => {

    const AuctionAllFn = async () => {
      await BidAll().then((response) => {
        const firstThreeObjects = response?.data?.data?.dataList.slice(0, 5);
        setItemListRecent(firstThreeObjects);
      })

    }
    AuctionAllFn();

  }, []);



  useEffect(() => {

    const BidWinFn = async () => {
      await BidWinnerList().then((response) => {
        setDataBidWindLn(response?.data?.length);
        setItemDataBidWin(response?.data);
      })

    }
    BidWinFn();

  }, []);

  useEffect(() => {

    const BidCountFn = async () => {
      await GetBidCountDetail().then((response) => {
        setItemCountData(response?.data);
      })

    }
    BidCountFn();

  }, []);




  useEffect(() => {
    async function apiCalling() {
      let res = await GetBidCountDetail();
      let response = await AuctionCount();
      setCountValue({ bidcount: res?.data?.totalBidCount, auctioncount: response?.activeCount });

    }
    apiCalling()
  }, [])




  useEffect(() => {

    const ReportDataFn = async () => {
      await GetBidAmountReport().then((res) => {
        console.log(res.data, "report>>>")
        setBidWonAmount(res?.data?.bidWonAmount)
        setInvestedBiddingAmount(res?.data?.investedBiddingAmount)

      })
    }
    ReportDataFn()

  }, [])





  const placeBtn = (e) => {
    const buttonValue = e.target.value;
    navigate(`/Player/PlaceBid?auctionId`, { state: buttonValue });
  }

  const handleButtonClick = () => {
    if (activeTab !== "Winner") {
      setActiveTab("Winner");

    }
    navigate("/Player/Bid", { state: activeTab })
  };
  return (
    <>
      <TopNavbar sidebarfun={sidebarfun} />
      <div className="dasboard-group" >

        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <h1 className="heading-dash">Dashboard</h1>
          <Row>
            <Col>
              <div className='item-list'>
                <div className='group-list1'>
                  <div className='image-icon2'>
                    <img src="../image/Bids.png" alt="user"></img>
                  </div>
                  <div className="content">
                    <h2>{countvalue?.bidcount == null ? "0" : countvalue?.bidcount}</h2>
                    <p>Total number of bids </p>
                  </div>
                </div>
              </div>
            </Col>

            <Col>
              <div className='item-list'>
                <div className='group-list1'>
                  <div className='image-icon1'>
                    <img src="../image/oragnization.svg"></img>
                  </div>
                  <div className="content">
                    <h2>{countvalue?.auctioncount == null ? "0" : countvalue?.auctioncount}</h2>
                    <p>Total running auction</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className='item-list'>
                <div className='group-list1'>
                  <div className='image-icon'>
                    <img src="../image/BidWon.png" alt="user"></img>
                  </div>
                  <div className="content">
                    <h2>{dataBidWindLn}</h2>
                    <p>Total number of bids won</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col>
              <div className='item-list'>
                <div className='group-list1'>
                  <div className='image-icon3'>
                    <img src="../image/BidLost.png" alt="user"></img>
                  </div>
                  <div className="content">
                    <h2>{itemCountData?.lostBidCount}</h2>
                    <p>Total number of bids lost</p>
                  </div>
                </div>
              </div>
            </Col>

          </Row>

          <Row>
            <Col sm={7}>
              <Card className="Bidder-sec mt-4 height-sec">
                <Row>
                  <Col md="6" xs="6">   <h2>Bid Won Listing</h2>

                  </Col>
                  <Col md="6" xs="6" className="text-right">
                    <Link to="/Player/Bid">View All</Link>

                  </Col>
                  <Col md="12 orgnization-table ">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Auction Name</th>
                          <th>Team Name</th>

                          <th>Bid Amount</th>
                          <th>Manager Name</th>


                        </tr>
                      </thead>
                      <tbody>
                        {
                          itemDataBidWin?.slice(0, 4).map((item) =>
                            <tr>
                              <td>{item?.auctionName}</td>
                              <td>{item?.teamName}</td>

                              <td>{item?.bidAmount}</td>
                              <td>{item?.createdByName}</td>


                            </tr>
                          )
                        }
                      </tbody>

                    </Table>

                  </Col>
                </Row>

              </Card>
            </Col>
            <Col sm={5} className='mt-4 chart'>
              <Card className='Chart-sec'>
                <h3>Reports</h3>


                <div className="group-sec">
                  <Row>
                    <Col md="6">
                    
                      <div className="group-chart">
                      <ChartAdd2 bidWonAmount={bidWonAmount} investedBiddingAmount={investedBiddingAmount}/>
                                <img
                                  src="../image/Group-11553.png"
                                  className="image-auction"
                                />
                              </div>
                      </Col>
                    
                    <Col lg="6">
                      <Row className='mt-3'>

                        <div className='d-flex'><div className='list-circle me-2'></div> <p className='text-color-tab'>Bid Won Amount - {bidWonAmount}</p></div>
                        <div className='d-flex '><div className='list-circle-up  me-2'></div><p className='text-color-tab'> Invested Bidding Amount - {investedBiddingAmount}</p></div>

                      </Row>
                    </Col>
                  </Row>

                  {/* <div className="total Balance">
                    Balance
                    <div className="amount-text">0</div>
                  </div> */}
                

                </div>


              </Card>
            </Col>

          </Row>
          <Row>
            <Col md="12 orgnization-table">
              <Card className='Bidder-sec mt-4'>
                <Row>
                  <Col md="6" xs="6">    <h2>Recent Bids</h2>

                  </Col>
                  <Col md="6" xs="6" className="text-right"><Link to="/Player/Bid">View All</Link></Col>
                </Row>

                <Table responsive>
                  <thead>
                    <tr>
                      <th>Auction Name</th>
                      <th>Team Name</th>
                      <th>Current Bid Amount</th>
                      <th>Min Bid</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Min Bid Increment</th>
                      <th>Manager Name</th>
                    </tr>
                  </thead>
                  <tbody>

                    {itemListRecent?.map((item) =>
                      <>
                        <tr>
                          <td>{item?.auctionName}</td>
                          <td>{item?.teamName}</td>
                          <td>{item?.currentBidAmount}</td>
                          <td>{item?.startingBid}</td>
                          <td>
                            {
                              dayjs(item?.startDate).format("MM/DD/YYYY")
                            }
                          </td>
                          <td>
                            {
                              dayjs(item?.startDate).format("HH:mm:ss")
                            }
                          </td>
                          <td>{item?.bidIncrement}</td>
                          <td>{item?.createdByName}</td>
                        </tr>
                      </>
                    )}

                  </tbody>
                </Table>

              </Card>
            </Col>
          </Row>
          <Row>


            {itemList?.slice(0, 4).map(item =>
              <Col>
                <Card className='p-2 group-image-auction'>
                  {item?.status == "Live" ? (
                    <Badge bg="success" className='LiveButton'>{item?.status}</Badge>
                  ) : item?.status == "Upcoming" ? (
                    <Badge bg="primary" className='LiveButton'>{item?.status}</Badge>
                  ) : (
                    <Badge bg="danger" className='LiveButton'>{item?.status}</Badge>
                  )}

                  {
                    item?.auctionImage == "" ? <img src="/image/auction.png" className='auctionImage' /> : <img src={ImageUrl + item?.auctionImage} className='img-fluid auctionImage'></img>
                  }

                  <h5 className='mt-3 auction-list-h'>{item?.auctionName}</h5>
                  <p>Start Date and Time: {item?.startDate}</p>

                  <button type='button' onClick={placeBtn} className='mb-3 mt-3 placeBid-button' value={item?.auctionId} >Place Bid</button>
                </Card>
              </Col>

            )}

          </Row>
          <Footer className="mt-2 mb-3" />
        </div>

      </div>
    </>

  )
}


