import React, { useEffect, useState } from 'react'
import Sidebar from "../../CommonComponent/sidebar/Sidebar"
import TopNavbar from "../../CommonComponent/topNavbar/TopNavbar"
import { Footer } from "../../Footer"
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DataTable from 'react-data-table-component';
import * as icons from 'react-bootstrap-icons';
import { ModalBox } from '../Outer/Modal';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap';
import { CloseRounded } from '@mui/icons-material';
import Image from 'react-bootstrap/Image';
import { AuctionGetAll, AuctionGetTeam } from '../../redux/auth-reducer/auth-service';
import moment from 'moment';

export const Admin_auction = () => {
  const [data, setData] = useState(false);
  const [showView, setShowView] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [itemListData, setitemListData]=useState([])
  const [TeamList, setTeamList] = useState([])


  function sidebarfun() {
    setData(!data)
  }

  const columns = [
    {
      name: 'Sports',
      selector: (row) => row?.sportsName,
      sortable: true,
    },

    {
      name: 'Contest',
      selector: (row) => row?.tournamentName,
      sortable: true,
    },
    {
      name: 'Auction Start Date',
      selector: (row) => moment.utc(row?.startDate).local().format('L H:mm:ss') ,
      sortable: true,
    },
    {
      name: 'Team Bidding Open',
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: 'Teams',
      selector: (row) => (<>
        <button className='tablebtn viewButton' onClick={() => viewFn(row)}><RemoveRedEyeOutlinedIcon />View</button></>),
      sortable: true,
    },

    {
      name: 'Leader Winner',
      selector: (row) => (<>
        <button className='tablebtn active-status'>Team 1</button></>),
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <button type='button' className='text-danger btn-aucion' ><icons.Trash /></button>
        </>),
      sortable: true,
    },

  ];

  const GetTeamList = async (id) => {
    await AuctionGetTeam(id).then((response) => {
        setTeamList(response?.data)

    })
}


  

  useEffect(() => {
    const getAllAuction = async () => {
      await AuctionGetAll().then((res) => {
    // console.log(res?.data?.data?.dataList,"fdsfd")
    setitemListData(res?.data?.data?.dataList);
      });
    }
    getAllAuction()
  }, [])


  const viewFn = (viewItem) => {
    GetTeamList(viewItem.auctionId)
    setShowView(true);
  }
  const handleClosed = () => {
    setShowView(false);
    setTeamList([])
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData = searchText ? itemListData?.filter((item) => {
    return item?.AuctionName?.toLowerCase().includes(searchText?.toLowerCase())

  })
    : itemListData;
  return (
    <>
      <div className="dasboard-group" >
        <TopNavbar sidebarfun={sidebarfun} />
        <Sidebar data={data} />
        <div className={data ? "dashboard-content full-contnet" : "dashboard-content"}>
          <h1 className="heading-dash">Auction</h1>
          <div className='group_data_table'>
            <div className='search-input filter d-flex align-items-center'>


              <label className='me-3'>Search</label>
              <input type="text" className='form-control mb-3 mt-3' value={searchText} onChange={handleSearch} />
            </div>
            <DataTable
              title="dfsfd"
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20]}
              paginationTotalRows={filteredData?.length}
            />
          </div>
        </div>
        <div className={data ? "footer" : "footer-content"}>
          <Footer />
        </div>
      </div>

      <ModalBox show={showView} title={<><h1 className="heading-dash">Teams</h1></>} body={
        <>
          <Table bordered hover className='table-team border'>
            <thead>
              <tr>

                <th>Logo</th>
                <th>Team Name</th>

              </tr>
            </thead>
            <tbody>
              {
                TeamList.map((e) => {

                  return  <tr>
                  <td><Image src={e.teamImage ? e.teamImage : '/image/team-icon.png'} width={30} /></td>
                  <td>{e.teamName}</td>
  
                </tr>
                })
              }
             
            </tbody>
          </Table>

        </>
      } footer={
        <>
          <Button variant="secondary close-btn" onClick={handleClosed} ><CloseRounded /></Button>

        </>
      } />
    </>
  )
}