import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authService from "./auth-service";
import { SetRefreshToken, SetToken, SetUser } from "../../utils/helper/helper";

const initialState = {
    isLoading: false,
    isError: null,
    isSuccess:false,
    message: "",
    userData: []
}

export const LoginUser = createAsyncThunk('login-user', async (userData, thunkAPI) => {
    try {
        const response = await authService.loginUserAPI(userData);
       
        const token = response?.data?.token;
        const refreshToken = response?.data?.refreshToken;
        const {email ,fullName , userId , roleId ,role} = response?.data
        const user =  {email ,fullName , userId , roleId };
 
        SetToken(token)
        SetRefreshToken(refreshToken)
        SetUser(user);
    

       
        return response
    }
    catch (error) {
        const message = (error.response && error.response.data && error.response.data.detail) || error.response.data.message || error.toString();
        return thunkAPI.rejectWithValue(message)
    }

})



const authReducer = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {

        setUserInfo : (state,action) => {
            state.userData = action.payload
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(LoginUser.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(LoginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                console.log("action.payload", action.payload)
                state.userData = action.payload;
              
                state.message = action.payload;
            })
            .addCase(LoginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = "Something went wrong!";
                state.message = action.payload;
            })
    }
})

export const { setUserInfo } = authReducer.actions






export default (authReducer.reducer);

