import React, { useEffect, useState } from 'react'
import TopNavbar from '../../CommonComponent/topNavbar/TopNavbar';
import Sidebar from '../../CommonComponent/sidebar/Sidebar';
import { OragnizationAll, OragnizationDelete, OragnizationUpdate } from '../../redux/auth-reducer/auth-service';
import DataTable from 'react-data-table-component';
import { Col, Row } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { GetToken } from '../../utils/helper/helper';
import Button from 'react-bootstrap/Button';
import { ModalBox } from '../Outer/Modal'
import { Footer } from '../../Footer';
import dayjs from 'dayjs';

export const ViewOrganization = () => {
    const [sidebar, setSidebar] = useState(false);
    const [apiData, setApiData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [count, setCount] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [item, setItem] = useState({
        managerID: '',
        firstName: '',
        lastName: '',
        managerName: '',
        orgEmailId: '',
        crudOperationId: false,
    });
    const [show, setShow] = useState(false);

    function sidebarfun() {
        setSidebar(!sidebar)
    }
    const { managerID, firstName, lastName, orgEmailId, managerName } = item
    useEffect(() => {
        dataApi()
    }, [count]);

    const dataApi = async (event) => {
        await OragnizationAll().then((res) => {
            setApiData(res?.data?.data?.dataList)

        })
    }

    const convertUTCToLocalTime = (utcDateString) => {
        const date = new Date(utcDateString);
        return date.toLocaleString();
    };

    const columns = [
        {
            name: <strong>Organization Name</strong>,
            selector: (row) => row?.orgName,
            sortable: true,
        },
        {
            name: <strong>Email</strong>,
            selector: (row) => row?.orgEmailId,
            sortable: true,
        },
        {
            name: <strong>Manager Name</strong>,
            selector: (row) => row?.firstName + " " + row?.lastName,
            sortable: true,
        },


        {
            name: <strong>Phone Number</strong>,
            selector: (row) => row?.phoneNumber,
            sortable: true,
        },
       
        {
            name: <strong>Join  Date</strong>,
            selector: (row) => dayjs(row?.createdDate).format("MM/DD/YYYY"),
            sortable: true,
        },
        {
            name: <strong>Join Time</strong>,
            selector: (row) => {
                const localTime = convertUTCToLocalTime(row?.createdDate + "Z");
                const [datePart, timePart] = localTime.split(", ");
                return (
                    <p>{timePart}</p>
                );
            },
            sortable: true,
        },
     


    ];
    const token = GetToken()

    const editFn = async (manager) => {
        setItem(manager)
        setShow(true)
    }

    const viewFn = (manager) => {
        setItem(manager)
        setShowModal(true)
    }



    const deleteFn = async (orgId, e) => {
        await OragnizationDelete(orgId, token).then((res) => {
            if (res?.data?.status) {
                toast.success("Delete Successfully");

            } else {
                toast.error(res?.message)
            }
        })
    }



    const handleSearch = (e) => {
        setSearchText(e.target.value);
    };

    const filteredData = searchText
        ? apiData.filter((item) => {
            return item?.firstName?.toLowerCase().includes(searchText?.toLowerCase())
        })
        : apiData;




    const handelform = (e) => {

        setItem({
            ...item,
            [e.target.name]: e.target.value
        })
    }
    const updateFn = async () => {
        await OragnizationUpdate(item, token).then((res) => {
            if (res?.status) {
                setCount(count + 1);
                handleClose();
                toast.success(res?.message);
            } else {
                toast.error(res?.message)
            }
        })
    }

    const handleClose = () => setShow(false);
    const handleClosed = () => {
        setShowModal(false);
        setShow(false);
    };





    return (
        <>




            <ModalBox show={show} onHide={handleClosed} title="Edit List"
                body={
                    <>
                        <input type='hidden' className='form-control' name="managerID" onChange={handelform} value={managerID}></input><br />
                        <input type='text' className='form-control' name="firstName" onChange={handelform} value={firstName}></input><br />
                        <input type='text' className='form-control' name="lastName" onChange={handelform} value={lastName}></input><br />
                        <input type='text' className='form-control' name="managerName" onChange={handelform} value={managerName}></input><br />
                        <input type='text' className='form-control' name="orgEmailId" onChange={handelform} value={orgEmailId}></input><br />
                        <Row>
                            <Col md="12" className='text-right'>
                                <Button variant="primary" onClick={updateFn}>Update</Button>
                            </Col>
                        </Row>

                    </>
                } />

            <ModalBox show={showModal} onHide={handleClosed} title="View List"
                body={
                    <>
                        <input type='text' className='form-control' value={firstName}></input><br />
                        <input type='text' className='form-control' value={lastName}></input><br />
                        <input type='text' className='form-control' value={managerName}></input><br />
                        <input type='text' className='form-control' value={orgEmailId}></input><br />
                    </>
                } />

            <TopNavbar sidebarfun={sidebarfun} />

            <div className="dasboard-group" >
                <Sidebar data={sidebar} />
                <div className={sidebar ? "dashboard-content full-contnet" : "dashboard-content"}>


                    <Row>
                        <Col md="10"><h1 className="heading-dash">Managers List</h1></Col>
                        <Col md="2 text-right mb-3"></Col>
                    </Row>

                    <div className='table-responsive'>
                        <div className='group_data_table View-organization'>
                            <div className='search-input d-flex align-items-center'>

                                <input type="text" className='form-control mb-3 mt-3' placeholder="Search..." value={searchText} onChange={handleSearch} />
                            </div>
                            <DataTable
                                title="Player List"
                                columns={columns}
                                data={filteredData}
                                pagination
                                paginationPerPage={10}
                                paginationRowsPerPageOptions={[5, 10, 15]}
                                paginationTotalRows={filteredData?.length}
                            />
                        </div>

                    </div>
                </div>
                <div className={sidebar ? "footer" : "footer-content"}>
                    <Footer />
                </div>
            </div>
        </>
    )

}







