import { Col, Row } from 'react-bootstrap';
function Footertwo() {
  return (
    <><footer className='landing'>

      <div className="container-fluid">

        <Row className='desktop-footer'>
          <Col>

            <p>Copyright © 2024 XCUTTA. All Rights Reserved.</p>
          </Col>
          <Col className='d-flex justify-content-end'>

            <p><a>Privacy Policy</a></p>
          </Col>

        </Row>
        <Row className='mob-footer'>
          <Col md="12">

            <p>Copyright © 2024 XCUTTA. All Rights Reserved.</p>
          </Col>
          <Col md="12">

            <p><a>Privacy Policy</a></p>
          </Col>

        </Row>
      </div>
    </footer></>
  );
}

export default Footertwo;